import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from '../../static/favicon.png';
import urljoin from 'url-join';

function SEO(
  // props,
  {
    siteTitle,
    seoTitle,
    metaImage,
    icon,
    path,
    description,
    keywords,
    datePublished,
    dateModified,
    is_app=false,
    appName="",
    appId=""
  }
) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `
  );

  console.log("path:", path);
  const nosnippet = (path === '/') || path.includes("privacy");

  const site_title = site.siteMetadata.title + ' | ' + siteTitle
  const metaDescription = description || site.siteMetadata.description;
  const canonical = path ? urljoin(site.siteMetadata.siteUrl, path) : null;

  const siteIcon = icon? icon : favicon
  
  // keywords
  // const kw_list = keywords.concat(site.siteMetadata.keywords) // merge two lists
  // if keywords provided by a page, use that, otherwise use site keywords
  const kw_list = (keywords.length > 0) ? keywords : site.siteMetadata.keywords
  const kw_uniq = [...new Set(kw_list)] // make list unique
  // console.log("kw_uniq:", kw_uniq)
  const kw_caps = kw_uniq.map(k => { // capitalize each keyword
    return (k.charAt(0).toUpperCase() + k.slice(1))
  })
  const kw = kw_caps.join(',') // comma separated list of keywords

  const image_src =
    metaImage && metaImage.src
      ? urljoin(site.siteMetadata.siteUrl, metaImage.src)
      : null;

  const image_alt = 
      metaImage && metaImage.alt
      ? metaImage.alt
      : '';
  
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: site.siteMetadata.siteUrl,
      name: site.siteMetadata.title
    }
  ];

  var articleJSONLD = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': canonical
    },
    dateModified: dateModified,
    datePublished: datePublished,
    headline: seoTitle,
    image: [image_src],
    author: {
      '@type': 'Organization',
      name: site.siteMetadata.author
    },
    publisher: {
     "@type": "Organization",
     name: site.siteMetadata.author,
    //  "logo": {
    //    "@type": "ImageObject",
    //    "url": "https://google.com/logo.jpg"
    //  }
    },
    isFamilyFriendly: true,
    keywords: kw,
    name: seoTitle,
    url: canonical,
    description: description
  };

  schemaOrgJSONLD.push(articleJSONLD);

  return (
    <Helmet>
      <title>{site_title}</title>

      <link rel="icon" type="image/png" href={siteIcon} />

      {/* Link tags */}
      <link rel="canonical" href={canonical} />

      {/* html attributes */}
      <html lang="en" />

      {/* Meta tags */}
      <meta charSet="utf-8" />
      <meta name="keywords" content={kw} />
      <meta name="description" content={metaDescription} />
      { nosnippet && <meta name="robots" content="nosnippet" />}

      <meta name="author" content={site.siteMetadata.author} />

      {/* Safari Smart App Banner */}
      {/* <meta name="apple-itunes-app" content={"app-id="+appId} /> */}

      {/* Twitter tags */}
      
      {/* App card */}
      {is_app && <meta name="twitter:card" content="app" />}
      {is_app && <meta name="twitter:site" content="@realfloofjournal" />}
      {/* {is_app && <meta name="twitter:app:name:iphone" content={appName} />}
      {is_app && <meta name="twitter:app:id:iphone" content={appId} />} */}
      
      {/* Summary card */}
      {is_app === false && <meta name="twitter:card" content="summary" />}
      
      {/* Common Info */}
      <meta name="twitter:title" content={seoTitle} />
      {image_src && <meta name="twitter:image" content={image_src} />}
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:creator" content={site.siteMetadata.author} />

      {/* Open Graph tags */}
      <meta property="og:title" content={seoTitle} />
      <meta property="og:type" content={"website"} />
      <meta property="og:url" content={canonical} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content={site.siteMetadata.title} />
      <meta property="og:description" content={metaDescription} />

      <meta property="article:published_time" content={datePublished} />
      <meta property="article:modified_time" content={dateModified} />

      {/* Open Graph image tags */}
      {image_src && <meta property="og:image" content={image_src} />}
      {image_src && <meta property="og:image:alt" content={image_alt} />}
      {image_src && <meta property="og:image:width" content={metaImage.width} />}
      {image_src && <meta property="og:image:height" content={metaImage.height} />}

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  meta: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  siteTitle: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }),
  path: PropTypes.string
};

export default SEO;
